.modal {
  max-width: 1024px;
  margin: 0 auto;
}

.side-box {
  position: absolute;
  margin-top: 1.5em;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-msg-box {
  padding: 0.5em;

  .msg {
    text-align: center;
    font-size: 1.2em;
    line-height: 1.8em;
  }
}

.form {
  .text {
    font-size: 1.1em;
    font-weight: bolder;
  }

  .refresh {
    margin-left: 1em;
  }
}
