$bg-primary: #001529;
$color-primary: #2185d0;

.layout {
  min-width: 660px;
}

.header {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  background-color: $bg-primary;

  .logo-container {
    padding: 0.5rem 0;
  }

  .logo {
    width: auto;
    height: 3.5rem;
    vertical-align: center;
  }

  .company {
    color: white;
    font-size: 1.25em;
    margin-left: 0.25rem;
  }

  .nav {
    flex-grow: 1;
  }

  .login-status {
    display: flex;
    flex-direction: column;
    color: white;

    .logout {
      justify-self: flex-end;
      align-self: flex-end;
      display: flex;
      align-items: center;
      gap: 0.25em;
      color: $color-primary;
    }
  }
}

.content {
  margin: 0 1em;
  padding: 2em 1em;
  min-height: calc(100vh - 134px);
}

.footer {
  text-align: center;
  width: 100%;
  background-color: $color-primary;
  color: white;
}
