.main {
  .header {
    display: flex;
    align-items: flex-start;
    gap: 1em;

    .title {
      font-size: 24px;
    }
  }

  li.active,
  li:hover {
    font-size: 1.1em;
    color: blue;
    font-weight: bolder;
  }

  .remove {
    cursor: pointer;
    font-size: 0.85em;
    padding: 0 1em;
    margin-left: 1em;
    visibility: hidden;
  }

  li:hover .remove {
    visibility: visible;
  }
}

.details {
  flex: 1 1 auto;
  margin-left: 1em;
}
